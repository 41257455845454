import React from "react";

const InvoiceTable = ({ items, currencyData }) => {
  return (
    <table className="invoice-new-table">
      <thead>
        <tr>
          <th>#</th>
          <th>Item & Description</th>
          <th>Wty</th>
          <th>Qty</th>
          <th>Price</th>
          <th>Discount</th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        {items?.map((item, index) => (
          <tr
            key={item.key || index}
            style={{
              height: "10px",
              lineHeight: "10px",
              padding: "2px 0",
            }}
          >
            <td>{index + 1}</td>
            <td style={{ textAlign: "left" }}>
              {item.name}
              {item?.serialNumber ? ` - ${item?.serialNumber}` : ""}
              {item?.description ? ` - ${item.description}` : ""}
            </td>
            <td>{item.warranty}</td>
            <td>{item.quantity}</td>
            <td>
              {currencyData ? `${currencyData} ` : "$"}
              {Number(item.rate).toLocaleString("en-IN", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </td>
            <td>
              {currencyData ? `${currencyData} ` : "$"}{" "}
              {Number(item.discount).toLocaleString("en-IN", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </td>
            <td>
              {currencyData ? `${currencyData} ` : "$"}{" "}
              {Number(item.amount).toLocaleString("en-IN", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default InvoiceTable;
