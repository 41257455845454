import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background-color: #f8f9fa;
  color: #343a40;
  font-family: Arial, sans-serif;
`;

const Heading = styled.h1`
  font-size: 4rem;
  margin: 0.5rem 0;
  color: #495057;
`;

const Subheading = styled.p`
  font-size: 1.25rem;
  margin: 1rem 0;
`;

const PageNotFound = () => {
  return (
    <Container>
      <Heading>404</Heading>
      <Subheading>Oops! The page you're looking for doesn't exist.</Subheading>
    </Container>
  );
};

export default PageNotFound;
