import React from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useParams,
} from "react-router-dom";
import Invoice from "./Invoice.tsx";
import PageNotFound from "./PageNotFound.tsx";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

function App() {
  const InvoiceWrapper = () => {
    let { invoice_id } = useParams();
    return <Invoice invoice_id={invoice_id} />;
  };

  return (
    <Router>
      <Routes>
        <Route path="/user_invoice/:invoice_id" element={<InvoiceWrapper />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));

export default App;
