export const toTitleCase = (str) => {
  if (typeof str !== "string" || str.trim() === "") {
    return str;
  }

  return str.toLowerCase().replace(/(?:^|\s)\w/g, function (match) {
    return match.toUpperCase();
  });
};

export const amountFormat = (value) => {
  return (value || 0).toLocaleString("en-IN", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};